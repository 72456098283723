
//
// Range slider
//

.irs--round{
  .irs-bar, .irs-to, .irs-from, .irs-single {
    background: $primary !important;
    font-size: 11px;
  }
  .irs-to, .irs-from, .irs-single{
    &:before{
      border-top-color: $primary;
    }
  }

  .irs-line{
    background: var(--#{$prefix}tertiary-bg);
    border-color: var(--#{$prefix}border-color);
  }
  .irs-grid-text{
    font-size: 11px;
    color: $gray-500;
  }
  .irs-min, .irs-max{
    color: $gray-500;
    background: var(--#{$prefix}tertiary-bg);
    font-size: 11px;
  }

  .irs-handle{
    border: 2px solid $primary;
    width: 12px;
    height: 12px;
    top: 31px;
    background-color: $card-bg !important;
  }
}