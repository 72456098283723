//
// Form editors.scss
//

// Tinymce

.tox-tinymce {
  border: 1px solid var(--#{$prefix}border-color) !important;
}

.tox {
  .tox-menu {
    background-color: var(--#{$prefix}secondary-bg) !important;
    border-color: var(--#{$prefix}border-color) !important;
    color: var(--#{$prefix}body-color) !important;

    .tox-collection__group {
      border-color: var(--#{$prefix}border-color) !important;
      .tox-collection__item {
        color: var(--#{$prefix}body-color) !important;

        &:active {
          background-color: var(--#{$prefix}tertiary-bg) !important;
        }
      }
    }
  }
  .tox-collection--toolbar {
    .tox-collection__item--active,
    .tox-collection__item--enabled {
      background-color: var(--#{$prefix}tertiary-bg) !important;
    }
    .tox-collection__item-icon {
      color: var(--#{$prefix}body-color) !important;
    }
  }
  .tox-statusbar {
    border-top: 1px solid var(--#{$prefix}border-color) !important;
  }
  .tox-menubar,
  .tox-edit-area__iframe,
  .tox-statusbar {
    background-color: var(--#{$prefix}secondary-bg) !important;
    // background: none !important;
  }
  .tox-mbtn {
    color: var(--#{$prefix}secondary-color) !important;
    &:hover:not(:disabled):not(.tox-mbtn--active) {
      background-color: var(--#{$prefix}tertiary-bg) !important;
    }
  }
  .tox-tbtn {
    &:active,
    &:hover {
      background-color: var(--#{$prefix}tertiary-bg) !important;
    }
  }

  .tox-toolbar,
  .tox-toolbar__overflow,
  .tox-toolbar__primary {
    background: var(--#{$prefix}tertiary-bg) !important;
  }

  .tox-toolbar__primary {
    border-top-color: var(--#{$prefix}border-color) !important;
  }

  .tox-tbtn {
    color: var(--#{$prefix}secondary-color) !important;
    svg {
      fill: var(--#{$prefix}secondary-color) !important;
    }
  }
  .tox-edit-area{
    background-color: var(--#{$prefix}secondary-bg) !important;
    .tox-edit-area__iframe {
      background-color: var(--#{$prefix}secondary-bg) !important;
    }
  }


  .tox-statusbar a,
  .tox-statusbar__path-item,
  .tox-statusbar__wordcount {
    color: var(--#{$prefix}secondary-color) !important;
  }

  &:not([dir="rtl"]) .tox-toolbar__group:not(:last-of-type) {
    border-right: 1px solid var(--#{$prefix}border-color) !important;
  }

  .tox-tbtn--enabled {
    background-color: var(--#{$prefix}tertiary-bg) !important;
  }
  .tox-split-button {
    &:focus {
      background-color: var(--#{$prefix}tertiary-bg) !important;
    }
    &:hover {
      box-shadow: 0 0 0 transparent !important;
    }
  }

  .tox-collection--list {
    .tox-collection__item--enabled {
      background-color: var(--#{$prefix}tertiary-bg) !important;
    }
    .tox-collection__item--active {
      background-color: var(--#{$prefix}tertiary-bg) !important;
    }
  }

  .tox-mbtn--active {
    background-color: var(--#{$prefix}tertiary-bg) !important;
  }

  .tox-selected-menu {
    background-color: var(--#{$prefix}secondary-bg) !important;
  }
}


.tox-tinymce-aux {
  z-index: 1000 !important;
}

// x editable

.editable-input {
  .form-control {
    display: inline-block;
  }
}

.editable-buttons {
  margin-left: 7px;
  .editable-cancel {
    margin-left: 7px;
  }
}
